/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Close,
  InlineButton,
  InlineButtonContainer,
  ModalBackground,
} from './modal.css';

import { Dialog } from '@reach/dialog';
import CircleSVG from 'images/svg/shapes/circle.svg';
import VisuallyHidden from '@reach/visually-hidden';

import '@reach/dialog/styles.css';

export default class Modal extends PureComponent {
  constructor() {
    super();

    this.state = {
      isOpen: false,
    };

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = ({ key }) => {
    const { isOpen } = this.state;
    const { open = isOpen, hideModal = this.hideModal } = this.props;

    if (key === 'Escape') {
      open && hideModal();
    }
  };

  /**
   * Disables scrolling when the modal is open, as suggested by
   * https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/dialog-modal/dialog.html
   * */
  disableScrolling(open) {
    if (open) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = null;
      document.documentElement.style.overflow = null;
    }
  }

  showModal() {
    this.setState({ isOpen: true });
  }

  hideModal() {
    this.setState({ isOpen: false });
  }

  render() {
    const {
      children,
      open = false,
      showModal = this.showModal,
      hideModal = this.hideModal,
      modalButtonText = 'Show Modal',
      variant = 'default',
    } = this.props;

    const { isOpen = open } = this.state;

    if (typeof document !== 'undefined') {
      this.disableScrolling(isOpen);
    }

    return (
      <>
        {variant === 'inline' ? (
          <>
            <InlineButtonContainer>
              <InlineButton onClick={showModal}>
                <CircleSVG />
                <span>{modalButtonText}</span>
              </InlineButton>
            </InlineButtonContainer>
          </>
        ) : (
          <Button onClick={showModal}>{modalButtonText}</Button>
        )}

        <Dialog isOpen={isOpen}>
          <ModalBackground onClick={hideModal}></ModalBackground>
          <Close onClick={hideModal}>
            <VisuallyHidden>Close</VisuallyHidden>
            <span aria-hidden>×</span>
          </Close>
          {children}
        </Dialog>
      </>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  modalButtonText: PropTypes.string,
  variant: PropTypes.string,
};
