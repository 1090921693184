import React from 'react';
import PropTypes from 'prop-types';
import Item from 'components/gallery/item';
import ModalItem from 'components/gallery/modalItem';
import posed from 'react-pose';
import { FlyInFromBottom } from 'constants/pose';
import { Container } from './gallery.css';

const StaggeredAnimation = posed.div({
  enter: {
    beforeChildren: true,
    staggerChildren: 125,
  },
});

const Gallery = ({ items, variant = 'default' }) => (
  <>
    {variant === 'default' && (
      <StaggeredAnimation>
        <Container>
          {items.map((item, i) => (
            <FlyInFromBottom key={i}>
              <Item {...item} />
            </FlyInFromBottom>
          ))}
        </Container>
      </StaggeredAnimation>
    )}

    {variant === 'modal' && (
      <Container>
        {items.map((item, i) => (
          <ModalItem {...item} key={i} />
        ))}
      </Container>
    )}
  </>
);

Gallery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  variant: PropTypes.string,
};

export default Gallery;
