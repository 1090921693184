import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Title, Copy, Name, ItemContainer, ItemCaption } from './item.css';
import { Link } from 'gatsby';

const Item = ({ name, title, copy, image, link }) => (
  <ItemContainer>
    {image && (
      <>
        {link ? (
          <Link to={link}>
            <Img
              fluid={image.childImageSharp ? image.childImageSharp.fluid : {}}
              alt={title}
            />
          </Link>
        ) : (
          <Img
            fluid={image.childImageSharp ? image.childImageSharp.fluid : {}}
            alt={title}
          />
        )}
      </>
    )}

    <ItemCaption>
      {link ? (
        <Link to={link}>
          <Name as="h4" dangerouslySetInnerHTML={{ __html: name }} />
        </Link>
      ) : (
        <Name as="h4" dangerouslySetInnerHTML={{ __html: name }} />
      )}

      <Title>{title}</Title>
      <Copy dangerouslySetInnerHTML={{ __html: copy }}></Copy>
    </ItemCaption>
  </ItemContainer>
);

Item.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
  copy: PropTypes.string,
  image: PropTypes.object,
};

export default Item;
