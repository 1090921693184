import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Modal from 'components/modal';
import { Container, ModalContainer, Title, Copy, Name } from './modalItem.css';

const ModalItem = ({ name, title, copy, image }) => (
  <Container>
    {image && (
      <>
        <Img fluid={image ? image.childImageSharp.fluid : {}} alt={title} />
        <Modal modalButtonText={name} variant="inline">
          <ModalContainer>
            <Img fixed={image ? image.childImageSharp.fixed : {}} alt={title} />
            <div>
              <Name as="h4">{name}</Name>
              <Title>{title}</Title>
              {'object' === typeof copy ? (
                <Copy
                  dangerouslySetInnerHTML={{
                    __html: copy.childMarkdownRemark.html,
                  }}
                />
              ) : (
                <Copy>{copy}</Copy>
              )}
            </div>
          </ModalContainer>
        </Modal>
      </>
    )}
  </Container>
);

ModalItem.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  copy: PropTypes.oneOf([PropTypes.string, PropTypes.shape({})]),
  image: PropTypes.object,
};

export default ModalItem;
