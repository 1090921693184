import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  position: relative;

  &:hover {
    button {
      opacity: 1;
    }
  }

  button {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -37.55%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 225ms ease;

    span {
      position: absolute;
    }

    svg {
      width: 75%;
      z-index: 0;
    }
  }
`;

export const ModalContainer = styled.div`
  display: flex;

  .gatsby-image-wrapper {
    min-width: 30%;
    margin-right: 2rem;
    ${'' /* float: left; */}
  }
`;

export const Name = styled.h4`
  font-family: Lora;
  font-size: 2.4rem;
  margin: 2rem 0 0.8rem;
  color: #2c2c2c;
  letter-spacing: -0.07px;
`;

export const Title = styled.span`
  display: block;
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: #2c2c2c;
  letter-spacing: 1.75px;
  margin-top: 0px;
  margin-bottom: 24px;
`;

export const Copy = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  color: #2c2c2c;
  letter-spacing: -0.1px;
  line-height: 24.88px;
  margin: 0 0 2rem;

  ${MEDIA.TABLET`
    margin-bottom: 4rem;
  `};
`;
