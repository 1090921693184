import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { colors } from 'constants/theme';

export const TitleContainer = styled.div`
  margin: ${props => props.margin || '10rem 0 3rem'};
  color: ${props => props.color || colors.collective_red};
  word-spacing: ${props => props.wordSpacing || 'inherit'};
  padding: 0 2rem;

  ${MEDIA.TABLET`
    margin: 3rem 0;
  `};

  ${MEDIA.PHONE`
    margin: 3rem 0 0;
  `};

  ${MEDIA.MIN_LG_DESKTOP`
    padding: 0;
  `};

  h1 {
    margin-bottom: 0.6rem;
  }
`;
