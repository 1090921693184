import posed from 'react-pose';

export const FlyInFromRight = posed.div({
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    x: '100%',
    opacity: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
});

export const FlyInFromLeft = posed.div({
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    x: '-100%',
    opacity: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
});

export const FlyInFromBottom = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '100%',
    opacity: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
});

export const FlyInFromTop = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    opacity: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
});

export const FadeIn = posed.div({
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
});

export const ClickableBox = posed.div({
  hoverable: true,
  pressable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.08,
  },
  press: {
    scale: 1.04,
  },
});
