import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from 'components/layout';
import Head from 'components/head';
import TeamRoster from 'components/sections/teamRoster';

import Section from '~v2/section';

import Wrapper from '~v2/elements/container/wrapper';
import Title from '~v2/elements/title';
import { TitleContainer } from '~v2/elements/title/title.css';
// import { colors } from 'constants/theme';

const Team = ({
  data: {
    teamJson: { title, roster_tier_1, roster_tier_2 },
  },
}) => (
  <Layout isSmallHeader={true}>
    <Head
      pageTitle={title}
      pageDescription="Trace has assembled a crack team of hemp experts from across the country to make software that elevates the industry, learn more!"
    />
    <Wrapper>
      <TitleContainer>
        <Title as="h1">{title}</Title>
      </TitleContainer>
      <Section
        boundsProps={{
          fluid: true,
          style: { width: '100%' },
          DESKTOP_DOWN: { padding: '0 2rem' },
          DESKTOP: { padding: '0 2rem' },
        }}
        center={{
          children: (
            <>
              <TierContainer>
                <TeamRoster roster={roster_tier_1} title="" />
              </TierContainer>
              <TierContainer>
                <TeamRoster roster={roster_tier_2} title="" />
              </TierContainer>
            </>
          ),
          boxProps: {
            fluid: true,
            shiftUp: true,
            DESKTOP_DOWN: { width: '100%' },
            DESKTOP_UP: { width: '100%' },
          },
        }}
      />
    </Wrapper>
  </Layout>
);

Team.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Team;

const TierContainer = styled.div``;

export const query = graphql`
  query TeamQuery {
    teamJson {
      title
      hero {
        title
        tagline
        color
      }
      roster_tier_1 {
        name
        copy {
          childMarkdownRemark {
            html
          }
        }
        image {
          childImageSharp {
            fluid(maxHeight: 400, quality: 80) {
              ...GatsbyImageSharpFluid
            }
            fixed(width: 320, height: 320) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      roster_tier_2 {
        name
        copy {
          childMarkdownRemark {
            html
          }
        }
        image {
          childImageSharp {
            fluid(maxHeight: 400, quality: 80) {
              ...GatsbyImageSharpFluid
            }
            fixed(width: 320, height: 320) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
