import React from 'react';
import PropTypes from 'prop-types';
import Box from 'components/box';
import Gallery from 'components/gallery';
import Title from 'components/title';
import { Container } from './teamRoster.css';

const TeamRoster = ({ title = 'The Team', content = null, roster = {} }) => (
  <Container className="team-roster">
    <Box>
      {title && <Title as="h3">{title}</Title>}
      {content && <p>{content}</p>}
      <Gallery variant="modal" items={roster} />
    </Box>
  </Container>
);

TeamRoster.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  roster: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TeamRoster;
