import styled from 'styled-components';
import { palette } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

export const ItemContainer = styled.figure``;

export const ItemCaption = styled.figcaption`
  a {
    color: ${palette.offBlack};
    text-decoration: none;

    &:hover {
      color: ${palette.lightGreyBlue};
    }
  }
`;

export const Name = styled.h4`
  font-family: Lora;
  font-size: 24px;
  line-height: 32px;
  margin: 16px 0 8px;
  letter-spacing: -0.07px;
`;

export const Title = styled.span`
  display: block;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  color: ${palette.offBlack};
  letter-spacing: 1.75px;
  margin-bottom: 24px;
`;

export const Copy = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  color: ${palette.offBlack};
  letter-spacing: -0.1px;
  line-height: 24.88px;
  margin: 0 0 2rem;

  ${MEDIA.TABLET`
    margin-bottom: 4rem;
  `};
`;
