import styled from 'styled-components';

export const Container = styled.section`
  position: relative;
  box-sizing: border-box;
  background-color: ${props => props.bgColor || '#fff'};

  .box {
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    h3 {
      font-family: Lora;
      font-size: 34px;
      color: #75b589;
      letter-spacing: -0.09px;
      margin: 0 4rem;
    }
  }

  & ~ .team-roster {
    padding: 0 4rem;
    margin-top: -2rem;
  }

  &:last-of-type {
    padding-bottom: 4rem;
  }
`;
